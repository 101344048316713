import { forwardRef } from 'react';
import styled from 'styled-components';

import Image from 'next/image';
import Link from 'next/link';
import useTranslation from 'shared/hooks/useTranslation';

const Box = styled.li`
  list-style-type: none;
  background: ${({ theme }) => theme.COLORS.V2.PRIMARY};

  a {
    color: ${({ theme }) => theme.COLORS.WHITE};
  }

  h4 {
    font-size: 0.9rem;
    font-weight: 700;
    line-height: 1.3rem;
    text-transform: uppercase;
  }

  p {
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 1.4rem;
  }

  h4,
  p {
    text-align: center;
    font-family: ${({ theme }) => theme.FONTS.MAIN};
  }

  img {
    filter: brightness(0) saturate(100%) invert(100%) sepia(1%) saturate(2343%) hue-rotate(217deg) brightness(114%)
      contrast(101%);
    transform: scale(0.7);
  }

  &:nth-child(4n + 4),
  &:nth-child(4n + 1) {
    background: white;

    a {
      color: ${({ theme }) => theme.COLORS.V2.PRIMARY};
    }

    img {
      filter: brightness(0) saturate(100%) invert(0%) sepia(0%) saturate(7500%) hue-rotate(77deg) brightness(96%)
        contrast(105%);
    }
  }

  @media ${({ theme }) => theme.DEVICE.MD} {
    h4 {
      font-size: 1.5rem;
      font-weight: 700;
      line-height: 2rem;
    }

    p {
      font-size: 1.4rem;
      font-weight: 300;
      line-height: 1.9rem;
    }
  }

  @media ${({ theme }) => theme.DEVICE.XL} {
    h4 {
      font-size: 1.8rem;
      font-weight: 700;
      line-height: 2.5rem;
      margin-top: 2rem;
    }

    p {
      font-size: 1.8rem;
      font-weight: 300;
      line-height: 2.5rem;
    }

    img {
      transform: unset;
    }

    &:nth-child(7n + 1),
    &:nth-child(7n + 5),
    &:nth-child(9),
    &:nth-child(7n + 3) {
      a {
        color: white;
      }
      background: ${({ theme }) => theme.COLORS.V2.PRIMARY};

      img {
        filter: brightness(0) saturate(100%) invert(100%) sepia(1%) saturate(2343%) hue-rotate(217deg) brightness(114%)
          contrast(101%);
      }
    }

    &:nth-child(2n + 2) {
      background: white;
      a {
        color: ${({ theme }) => theme.COLORS.V2.PRIMARY};
      }

      img {
        filter: brightness(0) saturate(100%) invert(0%) sepia(0%) saturate(7500%) hue-rotate(77deg) brightness(96%)
          contrast(105%);
      }
    }
  }
`;

const BoxInner = styled(Link)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding: 5em 1.2rem 1.2rem 1.2rem;
  gap: 1.2rem;
  height: 100%;
  text-decoration: none;

  @media ${({ theme }) => theme.DEVICE.XL} {
    padding: 10rem 2.4rem 1.2rem 2.4rem;
  }
`;

type CardProps = {
  data: {
    id: number;
    link: string;
    icon: string;
    name?: string;
    description?: string;
  };
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Card = forwardRef<HTMLDivElement, CardProps>(({ data }: CardProps, ref) => {
  const { t } = useTranslation('common');

  return (
    <Box>
      <BoxInner href={data.link} passHref>
        <Image alt="" src={`/img/shared/${data.icon}.svg`} width={43} height={43} />
        <h4>{t(`navbar.${data.name}`)}</h4>
        <p>{t(`navbar.${data.description}`)}</p>
      </BoxInner>
    </Box>
  );
});

export default Card;
