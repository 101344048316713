import Section from 'shared/components/Section';
import styled from 'styled-components';
import useTranslation from 'shared/hooks/useTranslation';
import GridDesktop from './GridDesktop';
import GridMobile from './GridMobile';

const StyledSection = styled(Section)`
  margin: 0 auto;
  max-width: 180rem;
  display: flex;
  height: 100%;
  padding: 0;
`;

const ImageWrapper = styled.div`
  display: none;

  @media ${({ theme }) => theme.DEVICE.XL} {
    display: block;
    background: url('/img/home/v2/grid/people-in-work.png') no-repeat;
    width: 40%;
    height: auto;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  padding: 3.2rem;
  margin-top: 4rem;
  position: relative;

  :after {
    content: '';
    position: relative;
    bottom: -3.2rem;
    left: 0;
    width: 15rem;
    height: 0.1rem;
    background: white;
  }

  h4 {
    font-size: 3.6rem;
    font-weight: 600;
    line-height: 4.5rem;
    margin-bottom: 1.6rem;
  }

  p {
    font-size: 2.6rem;
    font-weight: 300;
    line-height: 4rem;
  }

  h4,
  p {
    font-family: ${({ theme }) => theme.FONTS.MAIN};
    text-align: left;
    color: ${({ theme }) => theme.COLORS.WHITE};
  }
`;

const DesktopWrapper = styled.div`
  display: none;
  width: 100%;

  ${({ theme }) => theme.MEDIA.XL} {
    display: block;
  }
`;

const MobileWrapper = styled.div`
  width: 100%;

  ${({ theme }) => theme.MEDIA.XL} {
    display: none;
  }
`;

function GridSection(): JSX.Element {
  const { t } = useTranslation('homePage');

  return (
    <StyledSection>
      <ImageWrapper>
        <TextWrapper>
          <h4>{t('gridSection.title')}</h4>
          <p>{t('gridSection.description')}</p>
        </TextWrapper>
      </ImageWrapper>
      <DesktopWrapper>
        <GridDesktop />
      </DesktopWrapper>
      <MobileWrapper>
        <GridMobile />
      </MobileWrapper>
    </StyledSection>
  );
}

export default GridSection;
