import styled from 'styled-components';
import { SOLUTIONS_ITEMS_V2 } from 'shared/components/NavBar-v2/SubMenuItems';
import Card from './Card';

const Grid = styled.ul`
  overflow-x: auto;
  display: grid;
  grid-template-columns: repeat(6, 30rem);
  grid-template-rows: repeat(2, 35rem);

  @media ${({ theme }) => theme.DEVICE.XL} {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: repeat(4, 45rem);
  }
`;

function GridDesktop(): JSX.Element {
  return <Grid>{SOLUTIONS_ITEMS_V2?.map((data) => <Card data={data} key={data.id} />)}</Grid>;
}

export default GridDesktop;
