import Carousel from 'react-multi-carousel';
import styled from 'styled-components';

import { ArrowBtnLeftV2, ArrowBtnRightV2 } from 'shared/components/CarouselArrows';
import { SOLUTIONS_ITEMS_V2 } from 'shared/components/NavBar-v2/SubMenuItems';

import Card from './Card';

const CardWrapper = styled.div`
  display: grid;
  padding-bottom: 6rem;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 26rem 26rem;
  width: 100%;
`;

const CarouselWrapper = styled.div`
  background: ${({ theme }) => theme.COLORS.V2.ASH};
  overflow: hidden;
`;

const responsive = {
  mobile: {
    breakpoint: { min: 0, max: 2229 },
    items: 1,
  },
};

function GridMobile(): JSX.Element {
  return (
    <CarouselWrapper>
      <Carousel
        customRightArrow={<ArrowBtnRightV2 align="right" />}
        customLeftArrow={<ArrowBtnLeftV2 align="right" />}
        responsive={responsive}
        containerClass="carousel-container"
        showDots={false}
        ssr
        draggable
      >
        {[0, 4, 8].map((item) => (
          <CardWrapper key={item}>
            {SOLUTIONS_ITEMS_V2?.filter((x) => [1 + item, 2 + item, 3 + item, 4 + item].includes(x.id)).map((data) => (
              <Card key={data.id} data={data} />
            ))}
          </CardWrapper>
        ))}
      </Carousel>
    </CarouselWrapper>
  );
}

export default GridMobile;
